import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import FormControl from '@nib-components/form-control';
import Textbox, { TextboxProps } from '@nib-components/textbox';
import { FormikProps } from 'formik';

interface TextboxFormControlProps extends Omit<FormikProps<any>, 'values' | 'touched' | 'errors'> {
  value: string;
  isTouched?: boolean;
  fieldName: string;
  autoComplete?: string;
  label: string;
  id: string;
  isEmptyAndOptional?: boolean;
  textboxClassName?: string;
  valid?: boolean;
  error?: string;
  isDisabled?: boolean;
  inputMode?: TextboxProps['inputMode'];
  width?: string;
}

const TextboxFormControl = (props: TextboxFormControlProps) => {
  const {
    value,
    id,
    isTouched,
    label,
    fieldName,
    setFieldTouched,
    handleBlur,
    handleChange,
    autoComplete,
    valid,
    isEmptyAndOptional,
    error,
    textboxClassName,
    isSubmitting,
    isDisabled = false,
    inputMode = 'text',
    width = '100%',
  } = props;

  return (
    <FormControl
      onFocus={() => setFieldTouched(fieldName, false)}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
      validated={isTouched}
      valid={valid}
      id={id}
      name={fieldName}
      label={label}
      isEmptyAndOptional={isEmptyAndOptional}
      error={error}
      width={width}
      disabled={isDisabled || isSubmitting}
    >
      <Textbox autoComplete={autoComplete} className={textboxClassName} inputMode={inputMode} />
    </FormControl>
  );
};

export default TextboxFormControl;
